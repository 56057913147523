exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-collections-tsx": () => import("./../../../src/templates/collections.tsx" /* webpackChunkName: "component---src-templates-collections-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-insights-tsx": () => import("./../../../src/templates/insights.tsx" /* webpackChunkName: "component---src-templates-insights-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

